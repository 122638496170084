import { FC, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  IAdvertisingModalContentSettings,
  IAdvertisingModalSettings,
  IAdvertisingModalStyleSettings,
  IAdvertisingModalTargetingSettings,
} from '../../../types';
import { useAuthContext } from '../../../providers/auth/hooks/useAuthContext';
import { createAdvertisingModalAPI } from '../../../api';
import { PreviewAdvModal } from './components';
import { AdvertisingBuilderForm } from '../../../components/forms';

const OrganizationAdvertisingModalBuilderPage: FC = () => {
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const { organizationId = '' } = useParams();
  const navigate = useNavigate();

  const [modalStyleSettings, setModalStyleSettings] = useState<IAdvertisingModalStyleSettings>({
    common: {
      bdRadius: '8',
      bgColor: '#ffffff',
    },
    title: {
      textColor: '#000000',
      fontFamily: 'Courier New',
      fontSize: '28',
    },
    subtitle: {
      textColor: '#000000',
      fontFamily: 'Arial',
      fontSize: '18',
    },
    description: {
      textColor: '#828181',
      fontFamily: 'Arial',
      fontSize: '14',
    },
    ctaButton: {
      textColor: '#ffffff',
      fontFamily: 'Arial',
      fontSize: '16',
      bgColor: '#000000',
    },
    dismissButton: {
      textColor: '#000000',
      fontFamily: 'Arial',
      fontSize: '16',
      bgColor: '#ffffff',
    },
  });
  const [modalContentSettings, setModalContentSettings] = useState<IAdvertisingModalContentSettings>({
    title: 'Title',
    subtitle: 'Subtitle',
    description: '',
    cta_text: 'Go to',
    dismiss_text: 'Cancel',
  });
  const [modalTargetingSettings, setModalTargetingSettings] = useState<IAdvertisingModalTargetingSettings>({
    visitor_device: 'all',
    show_after_time_delay: 0,
    show_after_page_scroll: 0,
    // traffic_source: false,
    show_after_visited_pages: 0,
    exit_intent: false,
  });

  const changeStyleSettingsHandler = (newSettings: IAdvertisingModalStyleSettings) => {
    setModalStyleSettings(newSettings);
  };
  const changeContentSettingsHandler = (newSettings: IAdvertisingModalContentSettings) => {
    setModalContentSettings(newSettings);
  };

  const submitHandler = async (data: Partial<IAdvertisingModalSettings>) => {
    if (!token) return;

    try {
      const response = await createAdvertisingModalAPI(token, organizationId, data);

      if (response.code) return;

      navigate(`/organization/${organizationId}/advertising-modals`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to={`/organization/${organizationId}/advertising-modals`}>{t('organizationModals')}</Link>
        </li>
        <li className="breadcrumb-item" aria-current="page">
          {t('modalBuilder')}
        </li>
      </ul>

      <div className="card mb-0">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center">
            <h5>Advertising Modal Builder</h5>
            {/* <Link className="btn btn-primary" to={`/organization/${organizationId}/advertising-modal/builder`}>
            {t('create')}
          </Link> */}
          </div>
        </div>
        <div className="card-body">
          <AdvertisingBuilderForm
            onStyleSettingsChange={changeStyleSettingsHandler}
            onContentSettingsChange={changeContentSettingsHandler}
            onSubmit={submitHandler}
            initialValues={{
              content: modalContentSettings,
              configuration: modalStyleSettings,
              targeting: modalTargetingSettings,
            }}
          />
        </div>
      </div>
      <div className="card shadow-none mb-0">
        <div className="card-header">
          <h5>{t('preview')}</h5>
        </div>
        <div className="card-body">
          <PreviewAdvModal styles={modalStyleSettings} content={modalContentSettings} />
        </div>
      </div>
    </>
  );
};

export default OrganizationAdvertisingModalBuilderPage;
