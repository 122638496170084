import { API_ENDPOINT } from '../config/environment';
import { errorsHandler } from './utils';

export const getAllAdvertisingModalsByOrganizationAPI = async (token: string, organizationId: string) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/sign-up-forms/${organizationId}/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
  });
  return errorsHandler(response);
};

export const getAdvertisingModalAPI = async (token: string, organizationId: string, modalId: string) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/sign-up-form/${organizationId}/${modalId}/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
  });
  return errorsHandler(response);
};

export const createAdvertisingModalAPI = async (token: string, organizationId: string, data: any) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/sign-up-form/${organizationId}/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  });
  return errorsHandler(response);
};

export const updateAdvertisingModalAPI = async (token: string, organizationId: string, modalId: string, data: any) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/sign-up-form/${organizationId}/${modalId}/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  });
  return errorsHandler(response);
};

export const deleteAdvertisingModalAPI = async (token: string, organizationId: string, modalId: string) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/sign-up-form/${organizationId}/${modalId}/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
  });
  return errorsHandler(response);
};
