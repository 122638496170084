import { API_ENDPOINT } from '../config/environment';
import { errorsHandler } from './utils';

export const getAllSegmentsByOrganizationAPI = async (token: string, organizationId: string) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/segments-list/${organizationId}/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
  });
  return errorsHandler(response);
};

export const getNewSegmentAPI = async (token: string, organizationId: string) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/segment-new/${organizationId}/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
  });
  return errorsHandler(response);
};

export const getSegmentAPI = async (token: string, organizationId: string, segmentId: string) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/segment-t/${organizationId}/${segmentId}/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
  });
  return errorsHandler(response);
};

export const createSegmentAPI = async (token: string, organizationId: string, data: any) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/segment-t/${organizationId}/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  });
  return errorsHandler(response);
};

export const updateSegmentAPI = async (token: string, organizationId: string, segmentId: string, data: any) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/segment-t/${organizationId}/${segmentId}/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  });
  return errorsHandler(response);
};

export const deleteSegmentAPI = async (token: string, organizationId: string, segmentId: string) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/segment/${organizationId}/${segmentId}/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
  });
  return errorsHandler(response);
};
