/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { campaignsAPIDeleteCampaignData, campaignsAPIPatchCampaignDetailsData } from '../../../api/campaigns';
import { useNavigate } from 'react-router-dom';
import ErrorPopup from '../../../components/errors/ErrorPopup';

interface ModalProps {
  isOpen: boolean;
  onOpen: (open: boolean) => void;
  status: string;
  action?: string;
  token: string | null | undefined;
  campaignId: string;
  data: any;
}

const ConfirmActionModal: React.FC<ModalProps> = ({ isOpen, onOpen, status, data, token, campaignId, action }) => {
  const [show, setShow] = useState(isOpen);
  const [buttonText, setButtonText] = useState('');
  const navigate = useNavigate();

  const onClose = useCallback(() => {
    setShow(!show);
    onOpen(false);
  }, [onOpen, show]);

  const { t } = useTranslation();

  useEffect(() => {
    if (action) {
      if (action === 'cancel') setButtonText(t('cancelCompaign'));
      if (action === 'start') setButtonText(t('startCampaign'));
      if (action === 'delete') setButtonText(t('deleteCampaign'));
      if (action === 'pause') setButtonText(t('pauseCampaign'));
    }
  }, [action, t]);

  const [isPopup, setIsPopup] = useState(false);
  const [errorText, setErrorText] = useState('');

  const onStartCampaign = useCallback(
    (status: string) => {
      (async () => {
        try {
          const res = await campaignsAPIPatchCampaignDetailsData(data.id, token, { status });
          if (res.code) {
            setIsPopup(true);
            setErrorText(`${res.message}. Error code: ${res.code}`);
          } else if ((!res.success && res.status && res.status !== 200) || !res) {
            setIsPopup(true);
            setErrorText(res.status ? `Something went wrong. Error code: ${res.status}` : 'Something went wrong.');
          } else {
            navigate('/campaigns');
          }
        } catch (e) {
          console.log(e);
        }
      })();
    },
    [navigate, token, data]
  );

  const onDeleteCampaign = useCallback(
    (status: string) => {
      if (token) {
        (async () => {
          try {
            const res = await campaignsAPIDeleteCampaignData(token, { ids: [`${campaignId}`], status });
            if (res.code) {
              setIsPopup(true);
              setErrorText(`${res.message}. Error code: ${res.code}`);
            } else if ((!res.success && res.status && res.status !== 200) || !res) {
              setIsPopup(true);
              setErrorText(res.status ? `Something went wrong. Error code: ${res.status}` : 'Something went wrong.');
            } else {
              navigate('/campaigns');
            }
          } catch (e) {
            console.log(e);
          }
        })();
      }
    },
    [navigate, token, campaignId]
  );

  const onCampaign = useCallback(() => {
    if (status === 'canceled' && action === 'cancel') {
      onDeleteCampaign('canceled');
    }
    if (status === 'paused' && action === 'pause') {
      onStartCampaign('paused');
    }
    if (status === 'pending' && action === 'start') {
      onStartCampaign('pending');
    }
    if (status === 'deleted' && action === 'delete') {
      onDeleteCampaign('deleted'); //
    }
    if (status === 'processing') {
      //onStartCampaign('paused');
      //onDeleteCampaign('deleted'); //
    }
  }, [status, onDeleteCampaign, onStartCampaign, action]);

  const popupClose = useCallback((c: boolean) => {
    setIsPopup(c);
  }, []);

  return (
    <>
      {show ? (
        <>
          <div className="modal-container modal-container_top"></div>
          <div
            className="modal modal_top fade show"
            role="dialog"
            aria-hidden="true"
            style={{ display: 'block', zIndex: 2000 }}
          >
            <div className="modal-dialog" role="document">
              {isPopup ? <ErrorPopup isPopup={isPopup} errorCode={errorText} popupClose={popupClose} /> : null}
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLiveLabel">
                    {t('confirmAction')}
                  </h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>{t('confirmDeletion')}</p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={onClose}>
                    {t('Close')}
                  </button>
                  <button type="button" className="btn btn-primary" onClick={onCampaign}>
                    {buttonText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
export default ConfirmActionModal;
