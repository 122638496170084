import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { DateTime } from 'luxon';

import { ICampaign, TDaysOfTheWeek } from '../../types';
import { mapCampaign } from '../../lib/mappers';
import { useAuthContext } from '../../providers/auth/hooks/useAuthContext';
import { campaignsAPIGetCampaignDetailsData } from '../../api/campaigns';
import ErrorPopup from '../../components/errors/ErrorPopup';
import AudienceContent from './common/AudienceContent';
import Spinner from './common/Spinner';
import ConfirmActionModal from './modal/ConfirmActionModal';
import { AttachmentPreview } from '../../components/common';

import 'react-datepicker/dist/react-datepicker.css';

const CampaignDetails: FC = () => {
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const { id: campaignId = '' } = useParams();

  const [campaign, setCampaign] = useState<ICampaign | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionStatus, setActionStatus] = useState('');
  const [action, setAction] = useState('');

  useEffect(() => {
    (async () => {
      if (!token || !campaignId) {
        console.error('You miss token or campaignId');
        return;
      }

      setIsLoading(true);

      try {
        const res = await campaignsAPIGetCampaignDetailsData(campaignId, token);
        setCampaign(mapCampaign(res));

        if (res.code) {
          setIsPopup(true);
          setErrorText(`${res.message}. Error code: ${res.code}`);
        }

        if ((!res.success && res.status && res.status !== 200) || !res) {
          setIsPopup(true);
          setErrorText(res.status ? `Something went wrong. Error code: ${res.status}` : 'Something went wrong.');
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [token, campaignId]);

  const weekDays = useMemo(() => {
    return campaign?.weekDays.map<TDaysOfTheWeek>((day: string) => t(`weekdays.${day}`));
  }, [campaign?.weekDays.length, t]);

  const onCampaign = (actionStatus: string, action: string) => {
    setActionStatus(actionStatus);
    setAction(action);
    setIsModalOpen(true);
  };

  const onOpenModal = () => {
    setIsModalOpen(false);
  };

  const popupClose = (c: boolean) => {
    setIsPopup(c);
  };

  return (
    <div id="campaign-details">
      <div className="main-body">
        <div className="page-wrapper">
          <div className="task-detail-right">
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h5>{t('campaignDetails')}</h5>
                {campaign?.isEditable ? (
                  <Link to={`/campaign/${campaignId}/edit`} className="btn btn-secondary">
                    <div className="d-flex align-items-center">
                      <FeatherIcon className="mr-2" icon="edit-2" />
                      {t('edit')}
                    </div>
                  </Link>
                ) : null}
              </div>
              {!isLoading ? (
                <>
                  {isModalOpen ? (
                    <ConfirmActionModal
                      onOpen={onOpenModal}
                      isOpen={isModalOpen}
                      status={actionStatus}
                      token={token}
                      campaignId={campaign!.id}
                      data={campaign}
                      action={action}
                    />
                  ) : null}
                  <div className="card-block task-details">
                    {isPopup ? <ErrorPopup isPopup={isPopup} errorCode={errorText} popupClose={popupClose} /> : null}
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>
                            <FeatherIcon className="feather m-r-5 m-t-3m" icon="bar-chart" size={16} />
                            {t('status')}:
                          </td>
                          <td className="text-right">{campaign?.status && t(campaign.status)}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="table-separator"></div>
                    <table className="table" style={{ marginBottom: 0 }}>
                      <tbody>
                        <tr>
                          <td>
                            <FeatherIcon className="feather m-r-5 m-t-3m" icon="user" size={14} /> {t('name')}:
                          </td>
                          <td className="text-right">
                            <span>{campaign?.name}</span>
                          </td>
                        </tr>
                        {campaign?.channel ? (
                          <tr>
                            <td>
                              <FeatherIcon className="feather m-r-5 m-t-3m" icon="bar-chart-2" size={14} />
                              {t('channel')}:
                            </td>
                            <td className="text-right">{t(campaign?.channel)}</td>
                          </tr>
                        ) : null}
                        {campaign?.createDate ? (
                          <tr>
                            <td>
                              <FeatherIcon className="feather m-r-5 m-t-3m" icon="calendar" size={14} /> {t('created')}:
                            </td>
                            <td className="text-right">
                              {DateTime.fromISO(campaign?.createDate).toFormat('MMM dd, yyyy')}
                            </td>
                          </tr>
                        ) : null}
                        {campaign?.timezone ? (
                          <tr>
                            <td>
                              <FeatherIcon className="feather m-r-5 m-t-3m" icon="globe" size={14} /> {t('timezone')}:
                            </td>
                            <td className="text-right">{campaign?.timezone}</td>
                          </tr>
                        ) : null}
                        {campaign?.fromDate ? (
                          <tr>
                            <td>
                              <FeatherIcon className="feather m-r-5 m-t-3m" icon="calendar" size={14} />
                              {t('startDate')}:
                            </td>
                            <td className="text-right">
                              {' '}
                              {DateTime.fromISO(campaign?.fromDate).toFormat('MMM dd, yyyy')}
                            </td>
                          </tr>
                        ) : null}
                        {campaign?.fromTime ? (
                          <tr>
                            <td>
                              <FeatherIcon className="feather m-r-5 m-t-3m" icon="sunrise" size={14} /> {t('fromTime')}:
                            </td>
                            <td className="text-right">{campaign?.fromTime}</td>
                          </tr>
                        ) : null}
                        {campaign?.toTime ? (
                          <tr>
                            <td>
                              <FeatherIcon className="feather m-r-5 m-t-3m" icon="sunset" size={14} /> {t('toTime')}:
                            </td>
                            <td className="text-right">{campaign.toTime}</td>
                          </tr>
                        ) : null}
                        {campaign?.list ? (
                          <tr>
                            <td>
                              <FeatherIcon className="feather m-r-5 m-t-3m" icon="list" size={14} /> {t('lists')}:
                            </td>
                            <td className="text-right">{campaign?.list}</td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                    <table className="table" style={{ marginBottom: 0 }}>
                      <tbody>
                        {weekDays?.length ? (
                          <tr>
                            <td>
                              <FeatherIcon className="feather m-r-5 m-t-3m" icon="calendar" size={14} />{' '}
                              {t('daysOfWeek')}:
                            </td>
                            <td className="text-right">{weekDays.join(', ')}</td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                    <table className="table">
                      <tbody>
                        {campaign?.text ? (
                          <tr>
                            <td>
                              <FeatherIcon className="feather m-r-5 m-t-3m" icon="file-text" size={14} /> {t('text')}:
                            </td>
                            <td className="text-right">{campaign?.text}</td>
                          </tr>
                        ) : null}
                        {campaign?.subject ? (
                          <tr>
                            <td>
                              <FeatherIcon className="feather m-r-5 m-t-3m" icon="crosshair" size={14} /> {t('subject')}
                              :
                            </td>
                            <td className="text-right">{campaign?.subject}</td>
                          </tr>
                        ) : null}
                        {campaign?.attachments.length ? (
                          <tr>
                            <td>
                              <FeatherIcon className="feather m-r-5 m-t-3m" icon="link" size={14} /> {t('attachments')}:
                            </td>
                            <td className="d-flex justify-content-end gap-3">
                              {campaign.attachments.map((attachment, index) => (
                                <AttachmentPreview key={attachment + index} url={attachment} />
                              ))}
                            </td>
                          </tr>
                        ) : null}
                        {campaign?.effect ? (
                          <tr>
                            <td>
                              <FeatherIcon className="feather m-r-5 m-t-3m" icon="feather" size={14} /> {t('effect')}:
                            </td>
                            <td className="text-right">{t(campaign?.effect)}</td>
                          </tr>
                        ) : null}
                        {campaign?.utmSource ? (
                          <tr>
                            <td>
                              <FeatherIcon className="feather m-r-5 m-t-3m" icon="paperclip" size={14} />{' '}
                              {t('utmSource')}:
                            </td>
                            <td className="text-right">{campaign?.utmSource}</td>
                          </tr>
                        ) : null}
                        {campaign?.utmMedium ? (
                          <tr>
                            <td>
                              <FeatherIcon className="feather m-r-5 m-t-3m" icon="server" size={14} /> {t('utmMedium')}:
                            </td>
                            <td className="text-right">{campaign?.utmMedium}</td>
                          </tr>
                        ) : null}
                        {campaign?.utmCampaign ? (
                          <tr>
                            <td>
                              <FeatherIcon className="feather m-r-5 m-t-3m" icon="pen-tool" size={14} />{' '}
                              {t('utmCampaign')}:
                            </td>
                            <td className="text-right">{campaign?.utmCampaign}</td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                    <div className="d-flex gap-3 justify-content-end align-items-center">
                      {campaign?.status === 'pending' ? (
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={() => onCampaign('canceled', 'cancel')}
                        >
                          {t('cancelCompaign')}
                        </button>
                      ) : null}
                      {campaign?.status === 'draft' ? (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => onCampaign('pending', 'start')}
                          >
                            {t('startCampaign')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-light-danger"
                            onClick={() => onCampaign('deleted', 'delete')}
                          >
                            {t('deleteCampaign')}
                          </button>
                        </>
                      ) : null}
                      {campaign?.status === 'paused' ? (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => onCampaign('pending', 'start')}
                          >
                            {t('startCampaign')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-light-danger"
                            onClick={() => onCampaign('deleted', 'delete')}
                          >
                            {t('deleteCampaign')}
                          </button>
                        </>
                      ) : null}
                      {campaign?.status === 'processing' ? (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => onCampaign('paused', 'pause')}
                          >
                            {t('pauseCampaign')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-light-danger"
                            onClick={() => onCampaign('deleted', 'delete')}
                          >
                            {t('deleteCampaign')}
                          </button>
                        </>
                      ) : null}
                    </div>
                  </div>
                </>
              ) : (
                <Spinner top={30} />
              )}
            </div>
          </div>

          {!isLoading ? (
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h5>{t('recipients')}</h5>
              </div>
              <AudienceContent detailsBlock />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default CampaignDetails;
